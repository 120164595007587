var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Forfaits")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un forfait")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter un forfait à votre entreprise, suivez les étapes ci-dessous : ")]),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499465_AjouterUnForfait.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un forfait")]),_c('h4',[_vm._v(" Si vous souhaitez modifier un forfait, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499484_ModifierUnForfait.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer un forfait")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer un forfait, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682596851_Suppression forfait.mp4`,"controls":""}})]),_c('p',[_vm._v(" Attention : la suppression d'un forfait est irréversible. Si vous êtes sûr de vouloir supprimer le forfait, veuillez cliquer sur \"\"Supprimer\"\" pour confirmer. ")]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher ou filtrer les forfaits")]),_c('h4',[_vm._v("Recherche forfaits :")]),_vm._m(3),_c('h4',[_vm._v("Filtrage forfaits :")]),_vm._m(4),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682596560_Recherche et filtrage forfait.mp4',"controls":""}})]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un forfait, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Cliquez sur le bouton \"Forfaits\" dans le menu principal de la page d'accueil. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v(" Remplissez le formulaire d'ajout de forfait (* il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer votre nouveau forfait. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page \"Forfaits\".")]),_c('li',[_vm._v(" Trouvez le forfait que vous souhaitez modifier en utilisant la barre de recherche ou en parcourant la liste des forfaits existants. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" correspondant au forfait que vous souhaitez modifier. ")]),_c('li',[_vm._v(" Modifiez les informations souhaitées dans le formulaire de modification. (*il y a des champs obligatoires). ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer les modifications apportées au forfait. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des forfaits")]),_c('li',[_vm._v(" Trouvez le forfait que vous souhaitez supprimer et cliquez sur le bouton \"Supprimer\". ")]),_c('li',[_vm._v(" Confirmez la suppression : une fenêtre contextuelle apparaîtra pour confirmer la suppression, cliquez sur \"Supprimer\". ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Cliquez sur le bouton \"Forfaits\" dans le menu de navigation.")]),_c('li',[_vm._v(" Dans la barre de recherche, tapez le nom ou une partie du nom du forfait que vous recherchez. ")]),_c('li',[_vm._v("CLes résultats de la recherche s'afficheront en temps réel.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Cliquez sur le bouton \"Forfaits\" dans le menu de navigation.")]),_c('li',[_vm._v(" Dans la section \"Filtres\", sélectionnez l'option \"Afficher les forfaits publiques\" ou \"Masquer les forfaits publiques\" selon votre choix. ")]),_c('li',[_vm._v("Les résultats du filtrage s'afficheront automatiquement.")])])
}]

export { render, staticRenderFns }